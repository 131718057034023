export const imagesCars = [
  {
    original: "photos/cars/01-002.jpg",
    thumbnail: "photos/cars/01-002.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/01-005.jpeg",
    thumbnail: "photos/cars/01-005.jpeg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/01-001.jpg",
    thumbnail: "photos/cars/01-001.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/01-03.jpg",
    thumbnail: "photos/cars/01-03.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/01-04.jpg",
    thumbnail: "photos/cars/01-04.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/01.jpg",
    thumbnail: "photos/cars/01.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/01-02.jpg",
    thumbnail: "photos/cars/01-02.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/02.jpg",
    thumbnail: "photos/cars/02.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/03.jpg",
    thumbnail: "photos/cars/03.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/04.jpg",
    thumbnail: "photos/cars/04.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/05.jpg",
    thumbnail: "photos/cars/05.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/06.jpg",
    thumbnail: "photos/cars/06.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/07.jpg",
    thumbnail: "photos/cars/07.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/08.jpg",
    thumbnail: "photos/cars/08.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/09.jpg",
    thumbnail: "photos/cars/09.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/10.jpg",
    thumbnail: "photos/cars/10.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/cars/11.jpg",
    thumbnail: "photos/cars/11.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
];

export const imagesDrones = [
  {
    original: "photos/drones/01.jpg",
    thumbnail: "photos/drones/01.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/drones/02.jpg",
    thumbnail: "photos/drones/02.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/drones/03.jpg",
    thumbnail: "photos/drones/03.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/drones/04.jpg",
    thumbnail: "photos/drones/04.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
];

export const imagesHelp = [
  {
    original: "photos/help/01-005.jpg",
    thumbnail: "photos/help/01-005.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/01-003.PNG",
    thumbnail: "photos/help/01-003.PNG",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
 {
    original: "photos/help/01-101.jpg",
    thumbnail: "photos/help/01-101.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/01-102.jpg",
    thumbnail: "photos/help/01-102.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/01-002.jpg",
    thumbnail: "photos/help/01-002.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/01-001.jpg",
    thumbnail: "photos/help/01-001.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/02.jpg",
    thumbnail: "photos/help/02.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/03.jpg",
    thumbnail: "photos/help/03.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/04.jpg",
    thumbnail: "photos/help/04.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/05.jpg",
    thumbnail: "photos/help/05.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/06.jpg",
    thumbnail: "photos/help/06.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/07.jpg",
    thumbnail: "photos/help/07.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/08.jpg",
    thumbnail: "photos/help/08.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/09.jpg",
    thumbnail: "photos/help/09.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/10.jpg",
    thumbnail: "photos/help/10.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/11.jpg",
    thumbnail: "photos/help/11.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
];

export const imagesTurbo = [
  {
    original: "photos/turbo/01-001.jpg",
    thumbnail: "photos/turbo/01-001.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/turbo/01-004.jpg",
    thumbnail: "photos/turbo/01-004.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/turbo/01-002.jpg",
    thumbnail: "photos/turbo/01-002.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/turbo/01-003.jpg",
    thumbnail: "photos/turbo/01-003.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/turbo/01.jpg",
    thumbnail: "photos/turbo/01.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/turbo/02.jpg",
    thumbnail: "photos/turbo/02.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/turbo/03.jpg",
    thumbnail: "photos/turbo/03.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  }
];
