import tt from "../../../helpers/translate.helper";
import PhotoGalleryCarousel from "../../common/photo-gallery/photo-gallery-carousel.component";
import { imagesTurbo } from "../../../images/images";

const TurboSection = () => (
  <section className="project-section">
    <div className="container">
      <h3 className="project-title" data-tr>
        {tt("Турбо Допомога")}
      </h3>
      <a
        className="contacts-row go-to-link"
        href="https://turbo.bslhelp.org.ua/"
      >
        {tt("До проекту")}
      </a>
      <PhotoGalleryCarousel items={imagesTurbo} />
      <a
        className="contacts-row go-to-link"
        href="https://turbo.bslhelp.org.ua/#donate">
        {tt("Підтримати")}
      </a>
    </div>
  </section>
);

export default TurboSection;
