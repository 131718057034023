import './partners.component.scss'
import tt from '../../helpers/translate.helper'

const PartnersComponent = () => {
  return (
    <div className='partners-component'>
      <div className='partners-wrapper'>
        <div className='partner-col'>
          <a href='https://savelife.in.ua/' target='_blank' rel='noreferrer'>
            <h3 className='partner-title'>{tt('Повернись Живим')}</h3>
            <div className='partner-inner'>
              <img src='/images/partners/partner-povernis-zhivym.jpg' alt='' />
            </div>
          </a>
        </div>

        <div className='partner-col'>
          <a
            href='https://www.instagram.com/oboroneni/'
            target='_blank'
            rel='noreferrer'
          >
            <h3 className='partner-title'>{tt('Оборонені')}</h3>
            <div className='partner-inner'>
              <img src='/images/partners/partner-oboroneni.jpg' alt='' />
            </div>
          </a>
        </div>

        <div className='partner-col'>
          <a href='https://www.donor.ua/' target='_blank' rel='noreferrer'>
            <h3 className='partner-title'>Donor.ua</h3>
            <div className='partner-inner'>
              <img src='/images/partners/partner-donor-ua.jpg' alt='' />
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default PartnersComponent
