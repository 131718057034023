import tt from "../../../helpers/translate.helper";
import PhotoGalleryCarousel from "../../common/photo-gallery/photo-gallery-carousel.component";
import { imagesHelp } from "../../../images/images";

const HelpSection = () => (
  <section className="project-section">
    <div className="container">
      <h3 className="project-title" data-tr>
        {tt("Братерська допомога")}
      </h3>
      <a
        className="contacts-row go-to-link"
        href="https://brothers.bslhelp.org.ua/"
      >
        {tt("До проекту")}
      </a>
      <PhotoGalleryCarousel items={imagesHelp} />
      <a
        className="contacts-row go-to-link"
        href="https://brothers.bslhelp.org.ua/#donate"
      >
        {tt("Підтримати")}
      </a>
    </div>
  </section>
);

export default HelpSection;
