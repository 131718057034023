import './home-page.component.scss'

import LinkScroll from '../common/link-scroll/link-scroll.component'
import React, { useState } from 'react'
import Partners from '../partners/partners.component'
import DronesSection from './sections/drones-section'
import CarsSection from './sections/cars-section'
import HelpSection from './sections/help-section'
import TurboSection from './sections/turbo-section'
import tt, { changeLang, getInitialLang } from '../../helpers/translate.helper'
import IconsList from '../contacts/iconsList.component.js'

const HomePageComponent = () => {
  const [lang, setLang] = useState(getInitialLang())
  const [openMenu, setOpenMenu] = useState(false);
  const onLangClick = value => {
    changeLang(value)
    setLang(value)
  }

  return (
    <div className='home-page-component'>
      <div className='container'>
        <nav className='site-nav'>
          <button className={openMenu ? "mobile-toggle-btn menu-active" : 'mobile-toggle-btn'} onClick={() => setOpenMenu(!openMenu)}></button>
          <div className={openMenu ? "container-nav  menu-active" : "container-nav"}>
            <LinkScroll to='#our-projects' data-tr>
              {tt('Наші проекти')}
            </LinkScroll>
            <LinkScroll to='#friends' data-tr>
              {tt('Друзі')}
            </LinkScroll>
            <LinkScroll to='#contacts' data-tr>
              {tt('Контакти')}
            </LinkScroll>
          </div>
          {openMenu && <div className='overlayForCloseMenu' onClick={() => setOpenMenu(false)}></div>}
          <section className='lang-nav'>
            <button
              onClick={() => onLangClick('UA')}
              className={`${lang === 'UA' ? 'active' : ''}`}
            >
              UA
            </button>
            <button
              onClick={() => onLangClick('EN')}
              className={`${lang === 'EN' ? 'active' : ''}`}
            >
              EN
            </button>
          </section>
        </nav>

        <img src='/images/logo.png' alt='' className='site-logo' />

        <h1 className='site-title' data-tr>
          {tt('Благородний Щедрий Лелека')}
        </h1>

        <p className='site-intro' data-tr>
          {tt('Лелека - це такий птаха що кружляє над рідною землею...')}
        </p>
      </div>
      <section id='our-projects' className='top-level-section'>
        <div className='section-title-wrapper'>
          <h2 className='section-title' data-tr>
            {tt('Наші проекти')}
          </h2>
        </div>

        <HelpSection />
        <TurboSection/>
        <CarsSection />

        <DronesSection />
      </section>
      <section id='friends' className='top-level-section'>
        <div className='section-title-wrapper'>
          <h2 className='section-title' data-tr>
            {tt('Друзі')}
          </h2>
        </div>

        <div className='container'>
          <Partners></Partners>
        </div>
      </section>
      <section id='contacts' className='top-level-section'>
        <div className='section-title-wrapper'>
          <h2 className='section-title' data-tr>
            {tt('Контакти')}
          </h2>
        </div>

        <div className='container'>
          <div className='contacts-wrapper'>
            <p className='contacts-row'>{tt('Харків, Україна')}</p>
            {/* <a className='contacts-row' href='tel:+380680436325'>
              +38 068 043-63-25
            </a> */}
            <a className='contacts-row' href='mailto:bslhelporg@gmail.com'>
              bslhelporg@gmail.com
            </a>
            <IconsList/>
          </div>
        </div>
      </section>
    </div>
  )
}

export default HomePageComponent
