import tt from "../../../helpers/translate.helper";
import PhotoGalleryCarousel from "../../common/photo-gallery/photo-gallery-carousel.component";
import { imagesCars } from "../../../images/images";

const CarsSection = () => {
  return (
    <section className="project-section ">
      <div className="container">
        <h3 className="project-title" data-tr>
          {tt("Автомобілі Братерства Щирих Людей")}
        </h3>

        <a className="contacts-row go-to-link" href="https://cars.bslhelp.org.ua/" aria-disabled="false">
          {tt("До проекту")}
        </a>
        <PhotoGalleryCarousel items={imagesCars} />
        <a className="contacts-row go-to-link" href="https://cars.bslhelp.org.ua/#donate" aria-disabled="false">
          {tt("Підтримати")}
        </a>
      </div>
    </section>
  );
};

export default CarsSection;
